import { Box, Typography } from '@mui/material';
import React from 'react';
import { Link } from 'react-router-dom';

const HTMLSitemap = ({ routes }) => {
  const groupedRoutes = routes.reduce((acc, route) => {
    const section = route.section || 'Other';
    if (!acc[section]) {
      acc[section] = [];
    }
    acc[section].push(route);
    return acc;
  }, {});

  return (
    <Box className="p-3">
      <h1 className="text-2xl font-bold mb-6">Site Map</h1>
      {Object.entries(groupedRoutes).map(([section, sectionRoutes]) => (
        <div key={section} className="mb-6">
          <Typography variant="body1" className="mb-3">
            {section}
          </Typography>
          <ul className="space-y-2">
            {sectionRoutes.map((route) => (
              <li key={route.path} className="ml-4">
                <Link to={route.path}>
                  <Typography color="text.secondary" variant="normal">
                    {route.title}
                  </Typography>
                </Link>
              </li>
            ))}
          </ul>
        </div>
      ))}
    </Box>
  );
};

export default HTMLSitemap;
