export const routeSEOConfig = {
  // Main Routes
  home: {
    title:
      'Solfin - Solar Financing Solutions for Residential, C & I and Others',
    description:
      'Access industry-leading solar financing solutions with competitive rates. Get expert guidance on solar loans, investments, and maximize your renewable energy savings.',
    keywords: [
      'solar financing',
      'solar loans',
      'rooftop solar finance',
      'renewable energy loans',
      'solar panel financing',
      'green energy finance',
      'solar power financing',
      'solar investment',
      'solar energy solutions',
      'solar finance solution',
      'sustainable energy financing',
      'low-interest solar loans',
      'flexible solar loan terms',
      'residential solar financing',
      'commercial solar financing',
      'solar financing options',
      'home solar loans',
      'commercial solar loans',
      'solar energy financing solutions',
      'solar power systems finance',
      'solar lease vs loan',
      'energy efficiency loans',
      'solar tax credits and incentives',
      'solar installation loan providers',
      'solar power for businesses',
      'solar loan calculator',
      'C&I solar financing',
      'solar equipment financing',
      'affordable solar loans',
      'solar financing for SMEs',
      'solar pay-as-you-go plans',
      'battery storage financing',
      'off-grid solar financing',
      'net metering solutions',
      'solar panel loan for homeowners'
    ]
  },
  login: {
    title: 'Secure Login | Solar Finance Management Portal',
    description:
      'Access your solar financing dashboard securely. Monitor your investments, track loan applications, and manage your renewable energy portfolio.',
    keywords: [
      'solar finance login',
      'solar account access',
      'solar loan management',
      'solar investment tracking',
      'solar financing portal',
      'solar loan dashboard',
      'solar customer login',
      'solar account portal',
      'solar finance account management',
      'solar payment history',
      'solar loan statement',
      'solar finance account login',
      'renewable energy loan portal',
      'solar financing sign-in',
      'solar repayment tracking',
      'solar loan balance check',
      'solar finance online access',
      'solar loan payment portal',
      'solar investment dashboard',
      'solar email login',
      'solar 2FA authentication',
      'solar account security',
      'two-factor authentication for solar finance',
      'secure solar finance login',
      'solar finance password recovery',
      'solar account verification',
      'multi-factor authentication solar finance',
      'solar finance user registration',
      'solar finance login protection',
      'solar loan account password reset'
    ]
  },
  register: {
    title: 'Start Your Solar Journey | Create Your Account',
    description:
      'Begin your transition to solar energy. Register for exclusive access to solar financing options, calculators, and personalized solutions.',
    keywords: [
      'solar financing registration',
      'solar account creation',
      'solar investment account',
      'new solar customer',
      'solar finance sign-up',
      'solar loan registration',
      'create solar finance account',
      'solar account setup',
      'solar customer onboarding',
      'solar loan application',
      'solar user registration',
      'solar financing enrollment',
      'solar investment account setup',
      'solar finance portal registration',
      'new user registration for solar finance',
      'solar payment account creation',
      'solar finance sign-up process',
      'solar loan customer registration',
      'start solar financing journey'
    ]
  },
  forgotPassword: {
    title: 'Password Recovery | Secure Account Access',
    description:
      'Safely recover access to your solar financing account. Quick and secure password reset process for your protection.',
    keywords: [
      'solar account recovery',
      'password reset',
      'account security',
      'solar portal access',
      'solar password recovery',
      'reset solar finance password',
      'solar finance account recovery',
      'forgot solar account password',
      'solar portal password reset',
      'secure password reset for solar finance',
      'solar account unlock',
      'recover solar login',
      'solar user authentication',
      'two-step verification reset',
      'solar password assistance',
      'solar login recovery',
      'multi-factor authentication recovery',
      'solar account protection',
      'update solar account credentials'
    ]
  },
  finance: {
    title: 'Solar Finance Options | Flexible Funding Solutions',
    description:
      'Discover tailored solar financing solutions for homes and businesses. Compare rates, terms, and find the perfect solar loan for your needs.',
    keywords: [
      'solar financing options',
      'solar loan programs',
      'solar payment plans',
      'solar funding solutions',
      'green energy financing'
    ]
  },
  registerCustomer: {
    title: 'Customer Registration | Solar Financing Made Easy',
    description:
      'Simple customer registration for solar financing. Get access to exclusive rates, personalized solutions, and expert guidance.',
    keywords: [
      'solar customer registration',
      'new solar client',
      'solar financing application',
      'solar loan customer'
    ]
  },
  premiumPartners: {
    title: 'Premium Solar Partners | Elite Network',
    description:
      'Connect with our network of premium solar partners. Access exclusive benefits, priority support, and preferred rates for solar financing.',
    keywords: [
      'solar premium partners',
      'elite solar network',
      'solar financing partners',
      'preferred solar installers'
    ]
  },
  solarCalculator: {
    title: 'Solar Savings Calculator | ROI Estimator',
    description:
      'Calculate your potential solar savings and ROI. Get accurate estimates for solar installation costs, financing options, and energy savings.',
    keywords: [
      'solar savings calculator',
      'solar ROI calculator',
      'solar investment calculator',
      'solar cost estimator',
      'solar financing calculator'
    ]
  },
  mySavings: {
    title: 'Track Your Solar Savings | Energy Investment Dashboard',
    description:
      'Monitor your solar investment performance. Track real-time savings, energy production, and financial benefits of your solar installation.',
    keywords: [
      'solar savings tracker',
      'solar investment monitoring',
      'solar ROI tracking',
      'solar performance metrics'
    ]
  },
  loanProductsResidential: {
    title: 'Residential Solar Loans | Home Solar Financing',
    description:
      'Specialized solar loans for homeowners. Get competitive rates, flexible terms, and expert guidance for your residential solar installation.',
    keywords: [
      'residential solar loans',
      'home solar financing',
      'house solar funding',
      'residential solar installation loans'
    ]
  },
  knowledge: {
    title: 'Solar Knowledge Base | Expert Resources',
    description:
      'Comprehensive solar financing resources and guides. Learn about solar technology, financing options, and investment strategies.',
    keywords: [
      'solar knowledge base',
      'solar financing guide',
      'solar investment resources',
      'solar education',
      'solar learning center'
    ]
  },

  // Policy Pages
  cookiePolicy: {
    title: 'Cookie Policy | Data Usage Guidelines',
    description:
      'Understanding our cookie usage policies and how we enhance your solar financing experience while protecting your privacy.',
    keywords: [
      'cookie policy',
      'data usage policy',
      'privacy guidelines',
      'website cookies',
      'user tracking policy'
    ]
  },
  privacyPolicy: {
    title: 'Privacy Policy | Your Data Security',
    description:
      'Our commitment to protecting your privacy and data security in solar financing transactions and account management.',
    keywords: [
      'solar privacy policy',
      'data protection',
      'financial privacy',
      'security measures'
    ]
  },
  dhanVikasPrivacypolicy: {
    title: 'DhanVikas Privacy Policy | Data Protection Guidelines',
    description:
      'Detailed privacy policy for Dhanvikas services, ensuring secure and transparent handling of your financial and personal information.',
    keywords: [
      'Dhanvikas privacy',
      'financial data protection',
      'banking privacy',
      'secure transactions'
    ]
  },
  refundPolicy: {
    title: 'Refund Policy | Customer Protection Guidelines',
    description:
      'Clear guidelines on our refund processes and customer protection measures for solar financing services.',
    keywords: [
      'solar refund policy',
      'money-back guarantee',
      'customer protection',
      'refund terms'
    ]
  },
  fairPracticeCode: {
    title: 'Fair Practice Code | Ethical Business Standards',
    description:
      'Our commitment to ethical business practices and fair treatment in solar financing and customer service.',
    keywords: [
      'fair practice code',
      'ethical standards',
      'business ethics',
      'customer fairness'
    ]
  },
  grievanceRedressalPolicy: {
    title: 'Grievance Redressal | Customer Support',
    description:
      'Comprehensive grievance redressal mechanism ensuring quick resolution of customer concerns and queries.',
    keywords: [
      'grievance redressal',
      'customer complaints',
      'dispute resolution',
      'support system'
    ]
  },
  termsAndConditions: {
    title: 'Terms & Conditions | Solar Financing Agreement',
    description:
      'Detailed terms and conditions for solar financing services, including rights, responsibilities, and service agreements.',
    keywords: [
      'solar terms conditions',
      'financing agreement',
      'service terms',
      'legal guidelines'
    ]
  },

  // Partner Pages
  epc: {
    title: 'EPC Partners | Solar Installation Network',
    description:
      'Join our network of certified EPC partners. Access exclusive financing options, project support, and growth opportunities.',
    keywords: [
      'solar EPC partners',
      'solar installation partners',
      'solar contractor network',
      'EPC collaboration'
    ]
  },
  dsa: {
    title: 'DSA Program | Solar Sales Partnership',
    description:
      'Become a Direct Selling Agent for solar financing. Earn competitive commissions while promoting sustainable energy solutions.',
    keywords: [
      'solar DSA program',
      'solar sales partnership',
      'solar financing agents',
      'solar business opportunity'
    ]
  },
  distributor: {
    title: 'Join Our Distributor Network | Solar Business Opportunity',
    description:
      'Become a solar product distributor. Access premium products, competitive pricing, and comprehensive business support.',
    keywords: [
      'solar distributor program',
      'solar business opportunity',
      'solar product distribution',
      'solar sales network'
    ]
  },

  // Admin Routes
  adminApplication: {
    title: 'Leads Application | Admin Dashboard',
    description:
      'Comprehensive lead management system for solar financing applications and customer tracking.',
    keywords: [
      'solar leads management',
      'application tracking',
      'admin dashboard',
      'lead processing'
    ]
  },
  adminEstimatorLeads: {
    title: 'Leads Estimator | Sales Pipeline Management',
    description:
      'Advanced tools for estimating and managing solar financing leads and sales opportunities.',
    keywords: [
      'solar lead estimation',
      'sales pipeline',
      'lead management',
      'solar sales tracking'
    ]
  },
  adminCustomers: {
    title: 'Customer Management | Admin Portal',
    description:
      'Centralized customer management system for tracking solar financing relationships and account status.',
    keywords: [
      'customer management',
      'solar accounts',
      'client tracking',
      'customer database'
    ]
  },
  adminLoans: {
    title: 'Loan Management | Admin Dashboard',
    description:
      'Comprehensive loan management system for tracking and processing solar financing applications.',
    keywords: [
      'loan management',
      'solar financing',
      'loan tracking',
      'financial admin'
    ]
  },
  adminEpcApplicants: {
    title: 'EPC Applicants | Partner Management',
    description:
      'Track and manage EPC partner applications and onboarding processes efficiently.',
    keywords: [
      'EPC applications',
      'partner onboarding',
      'contractor management',
      'solar installer tracking'
    ]
  },
  adminEpcEmpanelled: {
    title: 'Empanelled EPCs | Partner Network',
    description:
      'Manage approved EPC partners and track their performance in solar installations.',
    keywords: [
      'empanelled EPCs',
      'approved contractors',
      'solar installers',
      'partner network'
    ]
  },
  adminDistributorLeads: {
    title: 'Distributor Leads | Network Growth',
    description:
      'Track and manage potential distributor partnerships and business opportunities.',
    keywords: [
      'distributor leads',
      'business development',
      'partner acquisition',
      'network expansion'
    ]
  },
  adminDistributorKyc: {
    title: 'Distributor KYC | Verification Portal',
    description:
      'Manage distributor verification and KYC documentation processes securely.',
    keywords: [
      'distributor KYC',
      'partner verification',
      'documentation',
      'compliance check'
    ]
  },

  // Manufacturer Routes
  manufacturer: {
    title: 'Manufacturer Dashboard | Operations Center',
    description:
      'Comprehensive dashboard for managing solar manufacturing operations and partner relationships.',
    keywords: [
      'manufacturer dashboard',
      'solar production',
      'operations management',
      'partner coordination'
    ]
  },
  manufacturerComfortLettersSigned: {
    title: 'Signed Comfort Letters | Document Management',
    description:
      'Track and manage signed comfort letters and manufacturer agreements efficiently.',
    keywords: [
      'comfort letters',
      'manufacturer agreements',
      'document tracking',
      'partner contracts'
    ]
  },
  manufacturerEpcEmpanelled: {
    title: 'Manufacturer EPC Network | Partner Management',
    description:
      'Manage relationships with empanelled EPC partners and track installation quality.',
    keywords: [
      'EPC network',
      'quality management',
      'installer tracking',
      'partner relations'
    ]
  },

  // EPC Routes
  epcDashboard: {
    title: 'EPC Dashboard | Project Management',
    description:
      'Comprehensive dashboard for managing solar installation projects and client relationships.',
    keywords: [
      'EPC dashboard',
      'project management',
      'installation tracking',
      'solar projects'
    ]
  },
  epcRateMatrix: {
    title: 'Rate Matrix | Pricing Management',
    description:
      'Manage and update installation rates and pricing structures for solar projects.',
    keywords: [
      'rate matrix',
      'price management',
      'installation costs',
      'solar pricing'
    ]
  },
  epcProjects: {
    title: 'EPC Projects | Installation Management',
    description:
      'Track and manage ongoing solar installation projects and their progress.',
    keywords: [
      'solar projects',
      'installation management',
      'project tracking',
      'EPC operations'
    ]
  },

  // Customer Routes
  customer: {
    title: 'Customer Dashboard | Solar Management',
    description:
      'Your personal dashboard for managing solar installations and financing.',
    keywords: [
      'customer dashboard',
      'solar management',
      'account overview',
      'project tracking'
    ]
  },
  customerApplications: {
    title: 'Solar Applications | Status Tracking',
    description:
      'Track your solar financing applications and installation progress.',
    keywords: [
      'application status',
      'solar tracking',
      'financing applications',
      'installation progress'
    ]
  },
  customerNewApplication: {
    title: 'New Solar Application | Get Started',
    description:
      'Start your solar journey with a new financing application for your installation.',
    keywords: [
      'new application',
      'solar financing',
      'loan application',
      'get started'
    ]
  },
  customerProjects: {
    title: 'My Solar Projects | Installation Tracking',
    description:
      'Monitor your solar installation projects and track their progress.',
    keywords: [
      'solar projects',
      'installation tracking',
      'project management',
      'solar monitoring'
    ]
  },
  customerMonitoring: {
    title: 'Solar System Monitoring | Performance Tracking',
    description:
      "Real-time monitoring of your solar system's performance and energy production.",
    keywords: [
      'solar monitoring',
      'performance tracking',
      'energy production',
      'system analytics'
    ]
  },
  customerProfile: {
    title: 'Customer Profile | Account Management',
    description: 'Manage your solar financing account details and preferences.',
    keywords: [
      'customer profile',
      'account management',
      'personal details',
      'profile settings'
    ]
  },
  customerLoans: {
    title: 'My Solar Loans | Finance Management',
    description:
      'Track and manage your solar financing loans and payment schedules.',
    keywords: [
      'solar loans',
      'loan management',
      'payment tracking',
      'finance overview'
    ]
  },
  customerChangePassword: {
    title: 'Change Password | Account Security',
    description: 'Securely update your account password and security settings.',
    keywords: [
      'password change',
      'account security',
      'security settings',
      'profile protection'
    ]
  },

  // DSA Routes
  dsa: {
    title: 'DSA Onboarding | Partner Program',
    description:
      'Join our DSA network and start your solar financing business journey.',
    keywords: [
      'DSA onboarding',
      'partner program',
      'sales agent',
      'business opportunity'
    ]
  },
  dsaDashboard: {
    title: 'DSA Dashboard | Sales Management',
    description:
      'Comprehensive dashboard for managing your solar financing sales activities.',
    keywords: [
      'DSA dashboard',
      'sales management',
      'performance tracking',
      'lead management'
    ]
  },
  dsaMyLeads: {
    title: 'DSA Leads | Sales Pipeline',
    description:
      'Track and manage your solar financing leads and sales opportunities.',
    keywords: [
      'sales leads',
      'lead management',
      'pipeline tracking',
      'opportunity management'
    ]
  },
  dsaProjects: {
    title: 'DSA Projects | Client Management',
    description:
      "Monitor your clients' solar installation projects and financing status.",
    keywords: [
      'project tracking',
      'client management',
      'installation monitoring',
      'project status'
    ]
  },

  // Distributor Routes
  distributorDashboard: {
    title: 'Distributor Dashboard | Business Center',
    description:
      'Comprehensive dashboard for managing your solar distribution business.',
    keywords: [
      'distributor dashboard',
      'business management',
      'sales tracking',
      'performance metrics'
    ]
  },
  distributorPendingInvoices: {
    title: 'Pending Invoices | Financial Management',
    description: 'Track and manage pending invoices and payment collection.',
    keywords: [
      'pending invoices',
      'payment tracking',
      'financial management',
      'accounts receivable'
    ]
  },
  distributorVerifiedInvoices: {
    title: 'Verified Invoices | Payment Records',
    description:
      'Access and manage verified invoices and completed transactions.',
    keywords: [
      'verified invoices',
      'payment records',
      'transaction history',
      'financial tracking'
    ]
  },
  distributorLoanSummary: {
    title: 'Loan Summary | Financial Overview',
    description:
      'Comprehensive overview of active loans and financing arrangements.',
    keywords: [
      'loan summary',
      'financial overview',
      'lending status',
      'portfolio management'
    ]
  },
  distributorLoans: {
    title: 'Distributor Loans | Finance Management',
    description:
      'Manage and track solar financing loans and payment schedules.',
    keywords: [
      'distributor loans',
      'finance management',
      'loan tracking',
      'payment monitoring'
    ]
  }
};
